import React from 'react';
import styles from './LibraryPostFrame.module.scss';
import { LibraryPost } from '../../types';

interface LibraryPostFrameProps {
  post: LibraryPost;
}

const LibraryPostFrame = ({ post }: LibraryPostFrameProps) => {
  return (
    <div
      className={styles.content}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: post.html }}
    />
  );
};

export default LibraryPostFrame;
