import { Location } from '@reach/router';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import ogTwitterImage from '../../images/meta/og-twitter.png';
import ogImage from '../../images/meta/og.png';
import SITE_URL from '../../utils/siteUrl';

const DEFAULT_WIDTH = 1024;
const DEFAULT_HEIGHT = 512;

interface MetaProps {
  description?: string;
  image?: string;
  title?: string;
  openGraphType?: string;
  noindex?: boolean;
  canonicalPath?: string;
}

const Meta: React.SFC<MetaProps> = (props) => (
  <StaticQuery
    query={graphql`
      {
        allImageSharp {
          nodes {
            original {
              height
              width
              src
            }
          }
        }
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={({
      allImageSharp: { nodes: allImages },
      site: { siteMetadata: defaults },
    }) => (
      <Location>
        {({ location }) => {
          const {
            title = defaults.title,
            description = defaults.description,
            openGraphType = 'website',
          } = props;

          let imagePath = ogImage;
          let twitterImagePath = ogTwitterImage;
          let height = DEFAULT_WIDTH;
          let width = DEFAULT_HEIGHT;

          if (props.image) {
            imagePath = props.image;
            twitterImagePath = props.image;
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const imageData = allImages.find((i: any) =>
              imagePath.endsWith(i.original.src),
            );
            height = imageData?.original.height;
            width = imageData?.original.width;
          }

          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          const image = `${SITE_URL}${imagePath}`;

          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          const twitterImage = `${SITE_URL}${twitterImagePath}`;

          return (
            <Helmet title={title}>
              <meta name="description" content={description} />
              <meta name="image" content={image} />

              <meta property="og:description" content={description} />
              <meta property="og:title" content={title} />
              <meta property="og:type" content={openGraphType} />
              <meta
                property="og:url"
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                content={`${SITE_URL}${location.pathname}`}
              />
              <meta property="og:image" content={image} />
              {height && (
                <meta property="og:image:height" content={`${height}`} />
              )}
              {width && <meta property="og:image:width" content={`${width}`} />}

              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:creator" content="slab" />
              <meta name="twitter:description" content={description} />
              <meta name="twitter:image" content={twitterImage} />
              <meta name="twitter:title" content={title} />
              {props.noindex && <meta name="robots" content="noindex" />}

              {props.canonicalPath && (
                <link
                  rel="canonical"
                  // TODO: Fix this the next time the file is edited.
                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                  href={`${SITE_URL}${props.canonicalPath}`}
                />
              )}
            </Helmet>
          );
        }}
      </Location>
    )}
  />
);

export default Meta;
