import React from 'react';
import { graphql } from 'gatsby';
import LibraryPostFrame from '../components/library/LibraryPostFrame';
import { LibraryPostsYaml } from '../graphql-types';
import { toLibraryPost } from '../utils/toLibraryType';
import Layout from '../components/layout/Layout';
import Meta from '../components/layout/Meta';
import UntitledStyle from '../components/layout/UntitledStyle';

interface LibraryPostEmbedProps {
  data: {
    post: LibraryPostsYaml;
  };
}

const LibraryPostEmbed = ({ data: { post } }: LibraryPostEmbedProps) => {
  const postForEmbed = toLibraryPost(post);
  const canonicalPath = postForEmbed.path;

  return (
    <Layout>
      <Meta
        title={`${postForEmbed.title} by ${postForEmbed.creator.name} - Slab Library`}
        canonicalPath={canonicalPath}
      />
      <UntitledStyle>
        <LibraryPostFrame post={postForEmbed} />
      </UntitledStyle>
    </Layout>
  );
};

export const query = graphql`
  query LibraryPostPageQuery($slug: String!) {
    post: libraryPostsYaml(fields: { slug: { eq: $slug } }) {
      title
      description
      url
      html
      category {
        name
        fields {
          slug
        }
      }
      creator {
        color
        kind
        name
        picture {
          publicURL
        }
        url
      }
      fields {
        slug
      }
      related {
        title
        category {
          name
          fields {
            slug
          }
        }
        creator {
          kind
          name
          picture {
            publicURL
          }
          color
        }
        fields {
          slug
        }
      }
    }
  }
`;

export default LibraryPostEmbed;
