import classNames from 'classnames';
import React from 'react';
import styles from './UntitledStyle.module.scss';

interface UntitledLayoutProps {
  children: React.ReactNode;
  noBackground?: boolean;
}

const UntitledStyle: React.SFC<UntitledLayoutProps> = ({
  children,
  noBackground,
}) => (
  <div
    className={classNames(styles.untitledStyle, {
      [styles.background]: !noBackground,
    })}
  >
    {children}
  </div>
);

export default UntitledStyle;
